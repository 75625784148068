import { MenuItemVariant } from "custom-types/MenuItem";
import { centsToDollars } from "utils/currencyFormatter";
import { formatQuantity } from "utils/formatQuantity";

export function finalPrice(variant: MenuItemVariant, quantity: number): number {
  const price = variant.deal?.active
    ? centsToDollars(variant.deal.discountedPrice)
    : variant.price;
  return price * quantity;
}

/**
 * Outputs e.g.: "1 item @ 4g each", unless the quantity is "each", in which
 * case it will output only "1 item".
 */
export function quantityLabel(
  variant: MenuItemVariant,
  quantity: number,
): string {
  const itemCount = `${quantity} item${quantity === 1 ? "" : "s"}`;
  const size = formatQuantity({
    size: variant.quantity,
    unit: variant.unit,
  });

  if (size && size !== "each") {
    return `${itemCount} @ ${size} each`;
  } else {
    return itemCount;
  }
}

export function sortVariants(variants: MenuItemVariant[]): MenuItemVariant[] {
  return variants
    .slice()
    .sort(
      (a, b) =>
        Number(!!b.deal?.active) - Number(!!a.deal?.active) ||
        a.price - b.price,
    );
}
