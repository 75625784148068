import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useSelector } from "react-redux";

import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getCartSessionId } from "redux/selectors/cart";
import { getUserPrefersMedical } from "redux/selectors/user";
import logError from "utils/logError";
import { MENU_TITLES } from "utils/menuTypeUtils";

import Button from "components/botanic/Button";

import { getDispensaryPath } from "./utils/dispensary";

type NewBagProps = {
  inStoreCart?: boolean;
  newDispensary?: Dispensary;
  staleDispensary?: Dispensary;
  messaging: string;
  onKeepBagClick?: () => void;
  onNewBagClick: () => Promise<void>;
};

type DispensaryMessagingProps = {
  newDispensaryName?: string;
  staleDispensaryName?: string;
  staleDispensaryHref?: string;
};

const InStoreOrderingMessaging = ({
  newDispensaryName,
  staleDispensaryName,
}: {
  newDispensaryName?: string;
  staleDispensaryName?: string;
}) => {
  return (
    <div>
      <p className="mb-6">
        Would you like to empty your cart at {staleDispensaryName} and start a
        new in-store cart with {newDispensaryName}?
      </p>
      <p className="mb-0">
        Build your cart, bring it in, and show your budtender exactly what you
        want. They'll complete your order for you in store.
      </p>
    </div>
  );
};

const DispensaryMessaging = ({
  newDispensaryName,
  staleDispensaryName,
  staleDispensaryHref,
}: DispensaryMessagingProps) => {
  return (
    <p className="mb-0">
      You can start a new cart with {newDispensaryName}, but it will empty your
      existing cart with{" "}
      {staleDispensaryHref ? (
        <Link href={staleDispensaryHref} className="underline text-green">
          {staleDispensaryName}
        </Link>
      ) : (
        staleDispensaryName
      )}
      . Continue?
    </p>
  );
};

const MedRecMenuMessaging = () => {
  const userPrefersMedical = useSelector(getUserPrefersMedical);

  const [pendingBagItemMenuTypeLabels, bagMenuTypeLabels] = userPrefersMedical
    ? [MENU_TITLES.med, MENU_TITLES.rec]
    : [MENU_TITLES.rec, MENU_TITLES.med];
  return (
    <p className="mb-0">
      You’ve previously added goodies from the{" "}
      <b>{bagMenuTypeLabels.longLower} menu</b> at this dispensary. You'll need
      to start a new cart to add this item from the{" "}
      {pendingBagItemMenuTypeLabels.longLower} menu.
    </p>
  );
};

export const NewBag: React.FC<NewBagProps> = ({
  inStoreCart,
  newDispensary,
  staleDispensary,
  messaging,
  onKeepBagClick = (): void => {},
  onNewBagClick = (): void => {},
}) => {
  const cartSessionId = useSelector(getCartSessionId);
  const countryCode = useDomainCountryCode();

  const { publishEvent } = useEventTracker();
  const [creatingCart, setCreatingCart] = useState(false);

  const isDispMessaging = messaging === "dispensary";

  useEffect(() => {
    publishEvent({
      action: Action.impression,
      cartId: cartSessionId,
      category: Category.addToBag,
      dispensaryId: isDispMessaging ? newDispensary?.id : staleDispensary?.id,
      label: inStoreCart
        ? "existing bag - shop every menu"
        : isDispMessaging
          ? "existing bag"
          : "Med Rec menu message",
    });
  }, []);

  return (
    <div>
      <div className="mx-auto text-center pt-4 pb-8" style={{ maxWidth: 350 }}>
        {inStoreCart && isDispMessaging ? (
          <InStoreOrderingMessaging
            newDispensaryName={newDispensary?.name}
            staleDispensaryName={staleDispensary?.name}
          />
        ) : isDispMessaging ? (
          <DispensaryMessaging
            newDispensaryName={newDispensary?.name}
            staleDispensaryName={staleDispensary?.name}
            staleDispensaryHref={
              staleDispensary
                ? getDispensaryPath(staleDispensary, countryCode)
                : undefined
            }
          />
        ) : (
          <MedRecMenuMessaging />
        )}
      </div>

      <div className="absolute bottom-0 md:relative md:flex md:flex-row-reverse w-full md:w-auto border-t border-light-grey p-4 md:pb-0 -mx-lg">
        <Button
          data-testid="new_bag__start_new_bag"
          variant="secondary"
          disabled={creatingCart}
          onClick={async () => {
            setCreatingCart(true);

            publishEvent({
              action: Action.click,
              cartId: cartSessionId,
              category: Category.addToBag,
              dispensaryId: isDispMessaging
                ? newDispensary?.id
                : staleDispensary?.id,
              label: inStoreCart
                ? "create new - shop every menu"
                : isDispMessaging
                  ? "create new"
                  : "Med Rec New Cart",
            });

            try {
              await onNewBagClick();
            } catch {
              logError("Error on click in NewBag");
              setCreatingCart(false);
            }
          }}
        >
          {inStoreCart && !creatingCart
            ? "create in-store cart"
            : creatingCart
              ? "creating cart…"
              : "start a new cart"}
        </Button>
        <Button
          className="mx-auto text-sm font-normal text-default mt-lg md:mt-none md:flex items-center"
          onClick={onKeepBagClick}
          disabled={creatingCart}
          variant="text"
        >
          cancel
        </Button>
      </div>
    </div>
  );
};
