import consumerApi from "api/services/consumer-api";
import { MenuItem } from "custom-types/MenuItem";
import logError from "utils/logError";

import { mapMenuItemImages } from "./getMenuItem";

const getVariant = async (
  variantId: number,
  countryCode: string,
): Promise<MenuItem | undefined> => {
  try {
    const { data } = await consumerApi.get<MenuItem>(
      `/api/variants/v1/${variantId}`,
      {
        headers: {
          "x-country-code": countryCode,
        },
      },
    );

    return mapMenuItemImages(data);
  } catch (e) {
    logError(e.message, {
      functionName: "getVariant",
      service: "consumer",
      statusCode: e.statusCode,
    });
  }
};

export default getVariant;
