import React, { ReactElement } from "react";
import classNames from "classnames";

import OfferLabel from "../OfferLabel/OfferLabel";

interface Props {
  label: string;
  subLabel?: string | ReactElement;
  finePrint?: string;
  progress?: number;
  progressText?: string;
  onClickAll?: () => void;
  buttonRef?: React.MutableRefObject<null>;
  className?: string;
}

const OfferCard: React.FC<Props> = ({
  label,
  subLabel,
  finePrint,
  progress,
  progressText,
  onClickAll,
  buttonRef,
  className,
}) => {
  if (!subLabel && progressText) {
    subLabel = (
      <span
        className={classNames({ "font-bold": !!progress && progress >= 1 })}
      >
        {progressText}
      </span>
    );
  }
  return (
    <div className={`border border-light-grey rounded p-md ${className}`}>
      <OfferLabel
        label={`${label}${finePrint ? "*" : ""}`}
        progress={progress}
        onClickAll={onClickAll}
        buttonRef={buttonRef}
      />
      {subLabel && <div className="text-xs mt-sm">{subLabel}</div>}
      {finePrint && <div className="text-xs text-grey mt-sm">*{finePrint}</div>}
    </div>
  );
};

export default OfferCard;
