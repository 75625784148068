import React from "react";
import classNames from "classnames";
import Link from "next/link";

import { MenuItem } from "custom-types/MenuItem";

import Image from "components/Image";

export const MenuItemHeader: React.FC<{
  menuItem?: Omit<MenuItem, "dispensary">;
  href?: string;
  className?: string;
  children?: React.ReactNode;
}> = ({ children, className, href, menuItem }) => {
  if (!menuItem) {
    return null;
  }

  return (
    <div className={classNames("flex", className)}>
      {menuItem.thumbnailUrl && (
        <div className="w-[70px] h-[70px] flex-shrink-0">
          <Image
            className="object-contain flex-shrink-0"
            src={menuItem.thumbnailUrl}
            alt={menuItem.name}
            sizes={[70]}
          />
        </div>
      )}
      <div className="ml-sm">
        {href ? (
          <Link className="text-sm font-bold text-green block" href={href}>
            {menuItem.name}
          </Link>
        ) : (
          <span className="text-sm font-bold block">{menuItem.name}</span>
        )}
        {children}
      </div>
    </div>
  );
};
