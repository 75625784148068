import { Coordinates } from "custom-types/Coordinates";
import { Dispensary } from "custom-types/Dispensary";
import logError from "utils/logError";

import { convertToKilometers, getMilesBetween } from "./distance";

const getMilesFromAnyDispensary = (
  dispensary?: Dispensary | null,
  userLocation?: Coordinates,
): string | null => {
  const { distanceMi, primaryLocation } = dispensary || {};

  if (distanceMi) {
    return distanceMi.toFixed(1);
  } else if (primaryLocation) {
    try {
      return getMilesBetween("miles", userLocation, primaryLocation)?.toFixed(
        1,
      );
    } catch {
      logError("Error processing data", {
        functionName: "getMilesFromDispensary",
      });
      return null;
    }
  }

  return null;
};

type LocalizedDistance = {
  shortened: string | null;
  full: string | null;
};

const getLocalizedDistance = (
  miles: string | number | null,
  km: string | number,
  countryCode: string,
): LocalizedDistance => {
  if (!miles) {
    return {
      full: null,
      shortened: null,
    };
  }
  return {
    full: countryCode === "CA" ? `${km} km away` : `${miles} miles away`,
    shortened: countryCode === "CA" ? `${km} km` : `${miles} mi`,
  };
};

export const getLocalizedDistanceAnyDispensary = (
  countryCode: string,
  dispensary?: Dispensary | null,
  location?: Coordinates,
): LocalizedDistance => {
  const miles = getMilesFromAnyDispensary(dispensary, location);
  const km = convertToKilometers(miles);
  return getLocalizedDistance(miles, km ?? "", countryCode);
};

export const getDispensaryPath = (
  dispensary: Dispensary,
  countryCode: string,
): string => {
  let prefix: string;

  switch (dispensary.retailType) {
    case "clinic":
      prefix = "doctors";
      break;
    case "cbd-store":
      prefix = "cbd-store";
      break;
    default:
      prefix = countryCode === "CA" ? "cannabis-store" : "dispensary-info";
  }

  return `/${prefix}/${dispensary.slug}`;
};
