import React, { useEffect } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import { useSelector } from "react-redux";

import { Category } from "constants/events";
import { Cart } from "custom-types/Cart";
import { MenuItem } from "custom-types/MenuItem";
import { getCartSessionId } from "redux/selectors/cart";

import AlertIcon from "components/Icons/alert.svg";

import { MenuItemHeader } from "./MenuItemHeader";

export const noop = (): void => {
  /* do nothing */
};

type MenuItemUnavailableNoticeProps = {
  menuItem?: MenuItem;
  staleBag?: Cart;
  onKeepShoppingClick?: () => void;
  onCheckoutClick?: () => void;
  onViewCartClick?: () => void;
  eventCategory?: Category;
};

export const MenuItemUnavailableNotice: React.FC<
  MenuItemUnavailableNoticeProps
> = ({
  menuItem,
  staleBag,
  onKeepShoppingClick = noop,
  onCheckoutClick = noop,
  onViewCartClick = noop,
  eventCategory = "Add to Bag",
}) => {
  const cartSessionId = useSelector(getCartSessionId);

  useEffect(() => {
    trackEvent(eventCategory, "Impression", "Item Not Available Message", {
      cartId: cartSessionId,
    });
  }, []);

  return (
    <>
      <div className="flex text-xs border rounded px-sm py-xs bg-yellow-20 border-yellow-40 mb-md">
        <AlertIcon width="14" height="12" className="text-yellow" />

        <div className="ml-xs">
          Oh no! Item{" "}
          <b>
            {menuItem?.name || ""}{" "}
            {menuItem?.brandName ? `by ${menuItem.brandName}` : ""}
          </b>{" "}
          is no longer available for pickup and has been removed.
        </div>
      </div>

      <MenuItemHeader menuItem={menuItem} />

      <div className="mt-lg">
        {staleBag ? (
          <>
            <div className="text-xs text-center mb-lg">
              No worries, you still have{" "}
              <b>
                {staleBag.cartItems.length} other product
                {staleBag.cartItems.length > 1 ? "s" : ""}
              </b>{" "}
              in your cart.
            </div>
            <div className="flex flex-col md:flex-row md:flex-row-reverse md:border-t border-light-grey -mx-lg px-md pt-md -mb-xs">
              {/* TODO: Can be converted to next/link once /bag uses getServersideProps */}
              <a className="button" href="/bag" onClick={onCheckoutClick}>
                proceed to checkout
              </a>
              {/* TODO: Can be converted to next/link once /bag uses getServersideProps */}
              <a
                className="button button--text mx-auto text-sm text-default mt-lg md:mt-md"
                href="/bag"
                onClick={onViewCartClick}
              >
                view cart
              </a>
            </div>
          </>
        ) : (
          <div className="flex md:border-t border-light-grey -mx-lg px-md pt-md -mb-xs">
            <button
              className="flex-grow-0 mx-auto text-sm bg-white border-2 button text-default"
              onClick={onKeepShoppingClick}
            >
              keep shopping
            </button>
          </div>
        )}
      </div>
    </>
  );
};
