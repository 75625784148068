import { getVariantLabel } from "@leafly-com/web-utils";

export function formatQuantity({
  unit,
  size,
}: {
  unit: string;
  size: number;
}): string {
  const label: string = getVariantLabel({
    packageSize: size,
    packageUnit: unit,
  });

  if (/oz$/.test(label)) {
    return label.replace(/oz$/, "ounce");
  } else if (/[^m]g$/.test(label)) {
    const packageUnitMatch = label.match(/^\d+/);
    const unitSize = Number((packageUnitMatch && packageUnitMatch[0]) || 1);
    const unitCopy = unitSize === 1 ? "gram" : "grams";
    return label.replace(/g$/, unitCopy);
  } else if (/pc$/.test(label)) {
    return label.replace(/pc$/, "each");
  } else {
    return label;
  }
}
