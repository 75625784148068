import React from "react";
import classnames from "classnames";

import { MenuItemDeal, MenuItemVariant } from "custom-types/MenuItem";
import { centsToDollars, formatCurrency } from "utils/currencyFormatter";
import { formatQuantity } from "utils/formatQuantity";

const DealFlag = (deal?: MenuItemDeal) => {
  if (!deal?.active) {
    return null;
  }

  const { discountLabel } = deal;

  return (
    <div
      className="absolute z-10 top-0 flex right-0"
      data-testid="discount-tag"
    >
      <div
        className="inline-block border-notification"
        style={{
          borderLeft: "calc(var(--font-size-xs) + 8px) solid transparent",
          borderTopWidth: "calc(var(--font-size-xs) + 8px)",
        }}
      />

      <div className="inline-block text-white bg-notification font-bold leading-none rounded-tr text-xs p-xs">
        {discountLabel}
      </div>
    </div>
  );
};

type VariantTileProps = {
  className?: string;
  countryCode: string;
  selected?: boolean;
  variant: MenuItemVariant;
} & React.HTMLAttributes<HTMLDivElement>;

export const VariantTile: React.FC<VariantTileProps> = ({
  className,
  countryCode,
  selected = false,
  variant,
  ...props
}) => {
  const { deal, price, unit, quantity } = variant;
  const dealActive = !!deal?.active;
  const displayPrice = dealActive
    ? centsToDollars(deal.discountedPrice)
    : price;
  const displayUnit = formatQuantity({ size: quantity, unit });
  const { dollars, cents } = formatCurrency(displayPrice, countryCode);
  const { formattedPrice: formattedOriginalPrice } = formatCurrency(
    price,
    countryCode,
  );

  return (
    <div
      data-testid="variant-select__card"
      className={classnames(
        "relative h-full flex flex-col justify-end border rounded py-sm px-lg cursor-pointer",
        {
          "bg-green-20 border-deep-green-60": selected,
          "border-light-grey": !selected,
        },
        className,
      )}
      {...props}
    >
      {dealActive && DealFlag(deal)}

      {dealActive && (
        <div className="line-through text-xs">{formattedOriginalPrice}</div>
      )}

      <div
        data-testid="variant-select__price"
        className="flex items-start font-bold text-base"
      >
        <span>$</span>
        <span className="text-hero leading-none">{dollars}</span>
        <span>{cents}</span>
      </div>

      <span className="font-bold text-xs mt-xs">{displayUnit}</span>
    </div>
  );
};
