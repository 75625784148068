// This 'smart' component takes an array of offers or a single offer object
// Use displayList = true to list all offers, only when given an array
//   - for the two modals where multiple offers are available
// Use abridged = true to show the shortDisplayTitle instead of the title
//   - for non-OfferCard labels
// Use onClickAll to show a card with the 'see all' link, as of now just on PDPs

import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";

import { MenuItemVariantOffer } from "custom-types/MenuItem";
import { Offer } from "custom-types/Offer";

import OfferCard from "components/OfferCard/OfferCard";
import OfferLabel from "components/OfferLabel/OfferLabel";

interface Props {
  offers: MenuItemVariantOffer[] | Offer[];
  displayList?: boolean;
  abridged?: boolean;
  dealLink?: string;
  onClickAll?: () => void;
  buttonRef?: React.MutableRefObject<null>;
  gaCategory?: string;
  dispensaryName?: string;
}

const SubLabelAnchor = ({
  offerTitle,
  gaCategory,
  dealLink,
}: Pick<Props, "gaCategory" | "dealLink"> & { offerTitle: string }) => (
  <Link
    className="underline"
    onClick={() => trackEvent(gaCategory, "Click", "Browse eligible items")}
    href={`${dealLink}?deal_title[]=${encodeURIComponent(`${offerTitle}`)}`}
  >
    Browse eligible items
  </Link>
);

const SmartOfferCard: React.FC<Props> = ({
  offers,
  displayList,
  abridged,
  dealLink,
  onClickAll,
  buttonRef,
  gaCategory = "Unspecified",
}) => {
  const isSingleOffer = offers.length === 1;

  if (isSingleOffer) {
    const offer = offers[0];
    return abridged ? (
      <OfferLabel label={offer.shortDisplayTitle || ""} />
    ) : (
      <OfferCard
        label={offer.title}
        subLabel={
          <SubLabelAnchor
            gaCategory={gaCategory}
            dealLink={dealLink}
            offerTitle={offer.title}
          />
        }
        finePrint={offer.finePrint || undefined}
      />
    );
  }
  if (displayList) {
    return (
      <>
        {offers.map((offer, idx) => (
          <OfferCard
            key={`${offer.title}-${idx}`}
            label={offer.title}
            subLabel={
              <SubLabelAnchor
                gaCategory={gaCategory}
                dealLink={dealLink}
                offerTitle={offer.title}
              />
            }
            finePrint={offer.finePrint || undefined}
            className="my-md"
          />
        ))}
      </>
    );
  }
  if (onClickAll) {
    return (
      <OfferCard
        label={`${offers.length} offers available`}
        buttonRef={buttonRef}
        onClickAll={onClickAll}
      />
    );
  }
  return <OfferLabel label={`${offers.length} offers`} />;
};

export default SmartOfferCard;
